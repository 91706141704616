import { useState, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Sidebar from './components/Sidebar';
import Topbar from "./components/Topbar";

import Dashboard from './pages/Dashboard';
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";

import Sentiment from './pages/Sentiment';
import Classification from './pages/Classification';
import Translation from './pages/Translation';
import Names from './pages/Names';
function App() {
  const [theme, colorMode] = useMode();
  const [isSidebar, setIsSidebar] = useState(true);
  const [authToken, setAuthToken] = useState("");
  const [mapLoaded, setMapLoaded] = useState(false);

  useEffect(() => {
    const storedAuthToken = sessionStorage.getItem("authToken");
    setAuthToken(storedAuthToken);
  }, []);

  const handleLoadScript = () => {
    setMapLoaded(true);
  };

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">


      
            
                  <Sidebar isSidebar={isSidebar} />
                  <main className="content">
                    <Topbar setAuthToken={setAuthToken} setIsSidebar={setIsSidebar} />
                    <Routes>
                    <Route path="/" element={<Dashboard />} />
                      <Route path="/dashboard" element={<Dashboard />} />
                      <Route path="/sentiment-analysis" element={<Sentiment />} />
                      <Route path="/regional-classification" element={<Classification />} />
                      <Route path="/translate" element={<Translation />} />
                      <Route path="/names" element={<Names />} />
                    </Routes>
                  </main>
             
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
 );
}

export default App;
