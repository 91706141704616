import { Box } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// analyze icon
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import * as Icons from "@mui/icons-material";
import Header from "../components/Header";
import Lottie from "lottie-react";
import loadingAnimation from "./loading.json";
import Positive from "./positive.json";
import Negative from "./negative.json";
import { Map } from "algeria-map-ts";


import { useState, useEffect } from "react";
const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [mockDataContacts, setMockDataContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
const [region, setRegion] = useState("positive");
  const activities_icons = [
    { name: "piano",  icon: 'piano.png' },
    { name: "guitar", icon: 'guitar.png' },
    { name: "violin", icon: 'violin.png' },
    { name: "drawing", icon: 'drawing.png' },
    { name: "solfeggio", icon: 'music.png' },
  ];


  const icons = [
      { name: "positive", icon: 'positive.png', color: '#00FF00', description: 'The sentence provided is positive' },
      { name: "negative", icon: 'negative.png', color: '#FF0000', description: 'The sentence provided is negative' },
      { name: "neutral", icon: 'neutral.png', color: '#FFFF00', description: 'The sentence provided is neutral' },
  ];

  const [mockData, setMockData] = useState({
    Adrar: { value: false, name: "Adrar" },
    Alger: { value: false, name: "Alger" },
    Annaba: { value: false, name: "Annaba" },
    "Aïn Defla": { value: false, name: "Aïn Defla" },
    "Aïn Témouchent": { value: false, name: "Aïn Témouchent" },
    Batna: { value: false, name: "Batna" },
    Biskra: { value: false, name: "Biskra" },
    Blida: { value: false, name: "Blida" },
    "Bordj Badji Mokhtar": { value: false, name: "Bordj Badji Mokhtar" },
    "Bordj Bou Arreridj": { value: false, name: "Bordj Bou Arreridj" },
    Bouira: { value: false, name: "Bouira" },
    Boumerdès: { value: false, name: "Boumerdès" },
    Béchar: { value: false, name: "Béchar" },
    Béjaïa: { value: false, name: "Béjaïa" },
    "Béni Abbès": { value: false, name: "Béni Abbès" },
    Chlef: { value: false, name: "Chlef" },
    Constantine: { value: false, name: "Constantine" },
    Djanet: { value: false, name: "Djanet" },
    Djelfa: { value: false, name: "Djelfa" },
    "El Bayadh": { value: false, name: "El Bayadh" },
    "El Meghaier": { value: false, name: "El Meghaier" },
    "El Menia": { value: false, name: "El Menia" },
    "El Oued": { value: false, name: "El Oued" },
    "El Tarf": { value: false, name: "El Tarf" },
    Ghardaïa: { value: false, name: "Ghardaïa" },
    Guelma: { value: false, name: "Guelma" },
    Illizi: { value: false, name: "Illizi" },
    "In Guezzam": { value: false, name: "In Guezzam" },
    "In Salah": { value: false, name: "In Salah" },
    Jijel: { value: false, name: "Jijel" },
    Khenchela: { value: false, name: "Khenchela" },
    Laghouat: { value: false, name: "Laghouat" },
    "M'Sila": { value: false, name: "M'Sila" },
    Mascara: { value: false, name: "Mascara" },
    Mila: { value: false, name: "Mila" },
    Mostaganem: { value: false, name: "Mostaganem" },
    Médéa: { value: false, name: "Médéa" },
    Naâma: { value: false, name: "Naâma" },
    Oran: { value: false, name: "Oran" },
    Ouargla: { value: false, name: "Ouargla" },
    "Ouled Djellal": { value: false, name: "Ouled Djellal" },
    "Oum El Bouaghi": { value: false, name: "Oum El Bouaghi" },
    Relizane: { value: false, name: "Relizane" },
    Saïda: { value: false, name: "Saïda" },
    "Sidi Bel Abbès": { value: false, name: "Sidi Bel Abbès" },
    Skikda: { value: false, name: "Skikda" },
    "Souk Ahras": { value: false, name: "Souk Ahras" },
    Sétif: { value: false, name: "Sétif" },
    Tamanrasset: { value: true, name: "Tamanrasset" },
    Tiaret: { value: false, name: "Tiaret" },
    Timimoun: { value: false, name: "Timimoun" },
    Tindouf: { value: false, name: "Tindouf" },
    Tipaza: { value: false, name: "Tipaza" },
    Tissemsilt: { value: false, name: "Tissemsilt" },
    "Tizi Ouzou": { value: false, name: "Tizi Ouzou" },
    Tlemcen: { value: false, name: "Tlemcen" },
    Touggourt: { value: false, name: "Touggourt" },
    Tébessa: { value: false, name: "Tébessa" },
  
  });
  const assignColor = (value) => {
    if (value === true) {
      return "#800080";
    } else {
      return "#fff";
    }

  };
  
  // if you want static color for each wilaya simply add it the data object above
  // like that {"wilaya": {value: 12, color: "#fff"}}
  // add color range for each wilaya and add the color to the map
  const multipleColor = Object.entries(mockData).reduce((acc, [wilaya, value]) => {
    acc[wilaya] = { ...value, color: assignColor(value.value) };
    return acc;
  }, {});


  const wilayas = {
      "Algiers": ["Alger", "Blida", "Boumerdès", "Tipaza"],
      "Kabylia": ["Tizi Ouzou", "Béjaïa", "Bouira"],
      "Setif": ["Setif"],
      "Oran": ["Oran", "Sidi Bel Abbès", "Mostaganem"],
      "Constantine": ["Constantine", "Skikda"],
      "Tlemcen": ["Tlemcen", "Aïn Témouchent"],
      "Annaba": ["Annaba", "El Tarf"],
      "Aures": ["Batna", "Khenchela", "Oum El Bouaghi"],
      "Blida": ["Blida"],
      "Bordj Bou Arreridj / Setif": ["Bordj Bou Arreridj", "Setif"],
      "Djelfa": ["Djelfa", "M'Sila", "Laghouat"],
  };
  
  const fetchContacts = async () => {
    setLoading(true);

    try {
      const authToken = sessionStorage.getItem("authToken");
      // post, with body : authtoken, page, search
       const response = await fetch('https://api1.datalgeria.com/region', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: search }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch contacts');
      }

      

      const data = await response.json();
      console.log(data);

      let s = data.classification;
      s = JSON.parse(s);



      setRegion(s.region);

      if (s.region == "All") {
        Object.keys(mockData).forEach((wilaya) => {
          mockData[wilaya].value = true;
          console.log(mockData[wilaya].value);
        });
      } else {
        Object.keys(mockData).forEach((wilaya) => {
          mockData[wilaya].value = false;
          console.log(mockData[wilaya].value);
        });
        wilayas[s.region].forEach((wilaya) => {
          mockData[wilaya].value = true;
        });
      }


      setLoading(false);

    } catch (error) {
      console.error('Error fetching contacts:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };




  
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "user_id",
      headerName: "User ID",
        flex: 1,
    },
    { field: "total_amount", headerName: "Total Amount", flex: 1 },
    { field: "months", headerName: "Months", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },

  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

  return (
    <Box m="20px">
<Header
  title="Classification by region"
  subtitle="For recognizing the region of origin of a sentence in Algerian Darja or Tamazight"
/>
<p>
Given Algeria's vast geographical diversity, the Darja and Tamazight spoken in different regions vary significantly. This service can analyze a phrase and accurately identify the specific region it originates from, whether it's the coastal areas, the high plateaus, or the Sahara. By understanding these linguistic variations, we can provide deeper insights into cultural and regional identity across Algeria.
</p>
<br/>
<br/>

       <Box
        display="flex"
        backgroundColor={colors.primary[400]}
        borderRadius="3px"
      >
        <InputBase 
        // its value is 'search'
      value={search}
      // when change set the value of search to the value of the input
      onChange={(e) => setSearch(e.target.value)}

      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          fetchContacts();
        }
    }}

        sx={{ ml: 2, flex: 1 }} placeholder="Type your sentence in darja..." />
        <IconButton
        onClick={fetchContacts}
        type="button" sx={{ p: 1 }}>
          <SentimentSatisfiedOutlinedIcon />
        </IconButton>
      </Box>
      <Box height="600px" width="100%">

{loading && 
      <Lottie animationData={loadingAnimation} 
      style={{ width: 400, height: 400, margin: "auto" }} />

}
{!loading && 
<div style = {{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
}}
>

<Map
        data={multipleColor}
        stroke="#000"
        HoverColor="#F7cc79"
        hoverStroke="#F7cc79"
        getHoverContent={(wilaya) => `<div style="display: flex; flex-direction: column; align-items: center; justify-content: center; padding: 10px; background-color: #fff; border-radius: 5px; box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); color: #000; font-size: 14px; font-weight: 500; text-align: center;">
         ${wilaya.name}
        <div>`}

      />
</div>
}

</Box>




        
    </Box>
  );
};

export default Contacts;
