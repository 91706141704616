import { Box } from "@mui/material";
import { DataGrid, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector, GridToolbarExport } from "@mui/x-data-grid";
import { tokens } from "../theme";
import { useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { InputBase, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
// analyze icon
import SentimentSatisfiedOutlinedIcon from "@mui/icons-material/SentimentSatisfiedOutlined";
import * as Icons from "@mui/icons-material";
import Header from "../components/Header";
import Lottie from "lottie-react";
import loadingAnimation from "./loading.json";
import Positive from "./positive.json";
import Negative from "./negative.json";
import { useState, useEffect } from "react";
const Contacts = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [mockDataContacts, setMockDataContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [rowCount, setRowCount] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
const [sentiment, setSentiment] = useState("positive");
  const activities_icons = [
    { name: "piano",  icon: 'piano.png' },
    { name: "guitar", icon: 'guitar.png' },
    { name: "violin", icon: 'violin.png' },
    { name: "drawing", icon: 'drawing.png' },
    { name: "solfeggio", icon: 'music.png' },
  ];


  const icons = [
      { name: "positive", icon: 'positive.png', color: '#00FF00', description: 'The sentence provided is positive' },
      { name: "negative", icon: 'negative.png', color: '#FF0000', description: 'The sentence provided is negative' },
      { name: "neutral", icon: 'neutral.png', color: '#FFFF00', description: 'The sentence provided is neutral' },
  ];




  const fetchContacts = async () => {
    setLoading(true);

    try {
      const authToken = sessionStorage.getItem("authToken");
      // post, with body : authtoken, page, search
       const response = await fetch('https://api1.datalgeria.com/names', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ text: search }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch contacts');
      }

      

      const data = await response.json();

      let s = data.name_info;
      s = JSON.parse(s);

      setSentiment(s)


      setLoading(false);

    } catch (error) {
      console.error('Error fetching contacts:', error);
      return [];
    } finally {
      setLoading(false);
    }
  };




  
  const columns = [
    { field: "id", headerName: "ID", flex: 0.5 },
    {
      field: "user_id",
      headerName: "User ID",
        flex: 1,
    },
    { field: "total_amount", headerName: "Total Amount", flex: 1 },
    { field: "months", headerName: "Months", flex: 1 },
    { field: "date", headerName: "Date", flex: 1 },

  ];


  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </GridToolbarContainer>
    );
  }

return (
    <Box m="20px">
        <Header
            title="Algerian Names"
            subtitle="For analyzing comments or reviews"
        />
        <p>
            This model analyzes comments or reviews related to Algerian names. It takes into account the gender, region, and origin of the names, as well as their presence in the dataset. The model uses natural language processing techniques to determine the sentiment of the provided sentence. It classifies the sentence as positive, negative, or neutral based on the sentiment analysis.
        </p>
        <br />
        <br />

        <Box
            display="flex"
            backgroundColor={colors.primary[400]}
            borderRadius="3px"
        >
            <InputBase
                // its value is 'search'
                value={search}
                // when change set the value of search to the value of the input
                onChange={(e) => setSearch(e.target.value)}
                onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                        fetchContacts();
                    }
                }}
                sx={{ ml: 2, flex: 1 }}
                placeholder="Type your sentence in darja..."
            />
            <IconButton onClick={fetchContacts} type="button" sx={{ p: 1 }}>
                <SentimentSatisfiedOutlinedIcon />
            </IconButton>
        </Box>
        <Box height="600px" width="100%">
            {loading && (
                <Lottie
                    animationData={loadingAnimation}
                    style={{ width: 400, height: 400, margin: 'auto' }}
                />
            )}
            {!loading && (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'row',
                    }}
                >
                    <Lottie
                        animationData={sentiment.present == true ? Positive : Negative}
                        loop={false}
                        autoplay={true}
                        style={{ width: 400, height: 400 }}
                    />
                    <p
                        style={{
                            transform: 'translateX(-100px)',
                            fontSize: '20px',
                        }}
                    >
                      <b>Genre : </b>    {sentiment.g == "m" ? "Masculin" : "Feminin"}
                        <br />
                        <b>Signification : </b>{sentiment.signification}
                        {sentiment.region ?
                            <div>
                                <b>Region : </b>{sentiment.region}
                            </div>
                            : null}
                            
                    </p>
                </div>
            )}
        </Box>
    </Box>
);
};

export default Contacts;
