import { useState } from "react";
import { Sidebar as ProSidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, IconButton, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../theme";

import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import * as Icons from "@mui/icons-material";




const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Link to={to} style={{ textDecoration: "none" }}>
      <MenuItem
        active={selected === title}
        style={{
          color: colors.grey[100],
        }}
        onClick={() => setSelected(title)}
        icon={icon}
      >
        <Typography>{title}</Typography>
      </MenuItem>
    </Link>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  // Get from session: username, roles, avatar
  const username = sessionStorage.getItem("username");
  const roles_array = sessionStorage.getItem("role") || '[]';
  const avatar = sessionStorage.getItem("avatar");

  // Parse roles and join the array by -
  const roles = JSON.parse(roles_array).join(" - ");

  const roleCheck = (roles) => {
    try {
      const userRoles = JSON.parse(sessionStorage.getItem("role"));
      const hasRole = roles.length === 0 || roles.some((role) => userRoles.includes(role));
      console.log('Checking roles:', roles, 'User roles:', userRoles, 'Has role:', hasRole);
      return hasRole;
    } catch (e) {
      console.error('Error parsing roles from session storage', e);
      return false;
    }
  };
  

  const menuItems = [
    { header: "Home" },
    { title: "Dashboard", to: "/dashboard", icon: <Icons.DashboardOutlined />, roles: [] },
    
    { header: "NLP" },
    { title: "Translate", to: "/translate", icon: <Icons.TranslateOutlined />, roles: [] },
    { title: "Sentiment Analysis", to: "/sentiment-analysis", icon: <Icons.SentimentSatisfiedAltOutlined />, roles: [] },
    { title: "Classification", to: "/regional-classification", icon: <Icons.MapOutlined />, roles: [] },
  
    { header: "Data API" },
    { title: "Rate Exchange", to: "/rate-exchange", icon: <Icons.AttachMoneyOutlined />, roles: [] },
    { title: "Names", to: "/names", icon: <Icons.PersonOutlined />, roles: [] },
    { title: "News", to: "/news", icon: <Icons.ArticleOutlined />, roles: [] },
  
    { header: "Billing" },
    { title: "Subscriptions", to: "/subscriptions", icon: <Icons.MonetizationOnOutlined />, roles: [] },
    { title: "Services", to: "/services", icon: <Icons.BuildOutlined />, roles: [] },
    { title: "Informations", to: "/informations", icon: <Icons.InfoOutlined />, roles: [] },
    { title: "Free Trial", to: "/free-trial", icon: <Icons.FreeBreakfastOutlined />, roles: [] },
  
    { header: "Development" },
    { title: "API", to: "/api", icon: <Icons.ApiOutlined />, roles: [] },
    { title: "Custom Solutions", to: "/custom-solutions", icon: <Icons.SettingsOutlined />, roles: [] },
    { title: "Docs", to: "/docs", icon: <Icons.DescriptionOutlined />, roles: [] }
  ];
  

  return (
    <Box
    backgroundColor = "#111"
      sx={{
        "& .pro-sidebar-inner": {
          background: `${colors.primary[400]} !important`,
        },
        "& .pro-icon-wrapper": {
          backgroundColor: "transparent !important",
        },
        "& .pro-inner-item": {
          padding: "5px 35px 5px 20px !important",
        },
        "& .pro-inner-item:hover": {
          color: "#868dfb !important",
        },
        "& .pro-menu-item.active": {
          color: "#6870fa !important",
        },
      }}
    >
      <ProSidebar 
      backgroundColor = "#111"
      style = {{
        borderColor: "#111",

        
      }}
      collapsed={isCollapsed}>
        <Menu iconShape="square">
          {/* LOGO AND MENU ICON */}
          <MenuItem
            onClick={() => setIsCollapsed(!isCollapsed)}
            icon={isCollapsed ? <MenuOutlinedIcon /> : undefined}
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!isCollapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <img 
                  alt="logo"
                  width="100px"
                  src="/datalgeria4.svg"
                />
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          {!isCollapsed && (
            <Box mb="25px">
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  alt="profile-user"
                  width="100px"
                  height="100px"
                  src={`/user.png`}
                  style={{ cursor: "pointer", borderRadius: "50%" }}
                />
              </Box>
              <Box textAlign="center">
                <Typography
                  variant="h2"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0" }}
                >
                  {username}
                </Typography>
                <Typography variant="h5" color={colors.greenAccent[500]}>
                  {roles}
                </Typography>
              </Box>
            </Box>
          )}

          <Box paddingLeft={isCollapsed ? undefined : "10%"}>
            {menuItems.map((item, index) => (
              item.header ? (
                <Typography
                  key={index}
                  variant="h6"
                  color={colors.grey[300]}
                  sx={{ m: "15px 0 5px 20px" }}
                >
                  {item.header}
                </Typography>
              ) : (
                roleCheck(item.roles) && (
                  <Item
                    key={index}
                    title={item.title}
                    to={item.to}
                    icon={item.icon}
                    selected={selected}
                    setSelected={setSelected}
                  />
                )
              )
            ))}
          </Box>
        </Menu>
      </ProSidebar>
    </Box>
  );
};

export default Sidebar;
